import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import {
  bookCoach,
  getCoachTimeSlot,
  getCoachTimeSlotv2,
  profile,
  getSingleCoach,
  add_booking_coach_detail,
  getSingleCourse,
  getSingleCourseForCoach,
  bookCoachForOnline,
  bookCoachForOffline,
} from "../services/web/webServices";
import { Store } from "react-notifications-component";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Backdrop from "@mui/material/Backdrop";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "react-bootstrap/Modal";
import md5 from "md5";
import base_uri from "../api/base_url";
export default function BookCoach() {
  const css = `
    .content {
        font-size: 20px;
        color: blue;
      }

    `;

  const [timeSlotId, setTimeSlotId] = useState(null);
  const [userDetails, setUserDetails] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [onlineShow, setOnlineShow] = React.useState(false);
  const [getLoader, setLoader] = useState(true);
  const navigate = useNavigate();
  const param = useParams();
  const [getLoader1, setLoader1] = useState(true);
  let [getDetail, setDetail] = useState([]);
  const [button, setButton] = useState(false);
  const [getTimeSlot, setTimeSlot] = useState([]);
  let date = null;
  const [getSlots, setSlots] = useState([]);
  const [getSelectedDate, setSelectedDate] = useState([]);
  const [getSelectedSlot, setSelectedSlot] = useState(null);
  const [getValue, setValue] = useState([]);
  const [getDate, setDate] = useState("");
  const [getPrice, setPrice] = useState(0);
  const [getStudyDetail, setStudyDetail] = useState(null);
  const [getActive, setActive] = useState("online");
  const [getClass, setClass] = useState(null);
  const [getStudentLocation, setStudentLocation] = useState("");
  const [getOfflineDetail, setOfflineDetail] = useState(null);
  const [getOnlineDetail, setOnlineDetail] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);

  const [subjectList, setSubjectList] = useState(null);
  const [subjectDetail, setSubjectDetail] = useState(null);

  const [classBookingType, setClassBookingType] = useState("");

  const [getCoach1, setCoach1] = useState({});
  const [getSubject, setSubject] = useState([]);
  const [getGrade, setGrade] = useState([]);
  const [getService, setService] = useState([]);
  const params = new URLSearchParams({
    merchant_id: `${base_uri.merchant_id}`,
    merchant_key: `${base_uri.merchant_key}`,
    return_url: `${base_uri.url}/mycourse`,
    cancel_url: `${base_uri.url}/`,
    notify_url: `${base_uri.backend_base_uri}/notify_url`,
    m_payment_id: userDetails?._id,
    amount: getOfflineDetail?.payment_status,
    item_name: getOfflineDetail?.class_type,
    custom_str1: param?.course_id,
    custom_str2: getOfflineDetail?.coach_id,
    custom_str3: timeSlotId,
    custom_str4: getOfflineDetail?.slot_time,
    custom_str5: getStudyDetail,
    passphrase: `${base_uri.passphrase}`,
  });
  const paramsOnline = new URLSearchParams({
    merchant_id: `${base_uri.merchant_id}`,
    merchant_key: `${base_uri.merchant_key}`,
    return_url: `${base_uri.url}/mycourse`,
    cancel_url: `${base_uri.url}/`,
    notify_url: `${base_uri.backend_base_uri}/notify_url`,
    m_payment_id: userDetails?._id,
    amount: getOnlineDetail?.payment_status,
    item_name: getOnlineDetail?.class_type,
    custom_str2: timeSlotId,
    custom_str3: getOnlineDetail?.coach_id,
    custom_str4: getOnlineDetail?.slot_time,
    custom_str5: getStudyDetail,
    passphrase: `${base_uri.passphrase}`,
  });

  // Create an MD5 signature of it.
  const MD5Signature = md5(params.toString());
  const MD5SignatureOnline = md5(paramsOnline.toString());

  //    Offline Modal
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {/* Book Coach */} Buy Course
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-12">
                <h1 style={{ marginLeft: "41%", marginBottom: "5%" }}>
                  R {getOfflineDetail?.payment_status}
                </h1>
              </div>
            </div>
            <form action={`${base_uri.payfast_uri}`} method="post">
              <input
                type="hidden"
                name="merchant_id"
                value={`${base_uri.merchant_id}`}
              />
              <input
                type="hidden"
                name="merchant_key"
                value={`${base_uri.merchant_key}`}
              />
              <input
                type="hidden"
                name="return_url"
                value={base_uri.url + "/"}
              />
              <input type="hidden" name="cancel_url" value={base_uri.url} />
              <input
                type="hidden"
                name="notify_url"
                value={`${base_uri.backend_base_ngrok_uri}/notify_url`}
              />
              <input
                type="hidden"
                name="m_payment_id"
                value={userDetails?._id}
              />
              <input
                type="hidden"
                name="amount"
                value={getOfflineDetail?.payment_status}
              />
              <input
                type="hidden"
                name="item_name"
                value={getOfflineDetail?.class_type}
              />
              <input
                type="hidden"
                name="custom_str1"
                value={
                  param?.course_id +
                  "," +
                  classBookingType +
                  "," +
                  subjectDetail
                }
              />
              <input
                type="hidden"
                name="custom_str2"
                value={getOfflineDetail?.coach_id}
              />
              <input type="hidden" name="custom_str3" value={timeSlotId} />
              <input
                type="hidden"
                name="custom_str4"
                value={getOfflineDetail?.slot_time}
              />
              <input type="hidden" name="custom_str5" value={getStudyDetail} />
              <input type="hidden" name="custom_int1" value={getClass} />
              <input
                type="hidden"
                name="passphrase"
                value={`${base_uri.passphrase}`}
              />
              {/* <input type="hidden" name="signature" value={MD5Signature} /> */}
              <div className="row">
                <div className="col-md-3">
                  <button
                    type="submit"
                    style={{
                      width: "200px",
                      height: "49px",
                      marginLeft: "260px",
                      color: "white",
                      backgroundColor: "blue",
                      border: "none",
                    }}
                  >
                    {" "}
                    Pay
                  </button>
                </div>
              </div>
            </form>
          </>
        </Modal.Body>
      </Modal>
    );
  }
  // Ends

  // Online Booking
  function MyVerticallyCenteredModal1(props) {
    const uniqueTimeSlotIds = [
      ...new Set(getSlots.map((slot) => slot.timeSlotId)),
    ];
    const timeSlotId = uniqueTimeSlotIds.join(",");

    const uniqueSlotIds = [...new Set(getSlots.map((slot) => slot.slotIds))];
    const SlotId = uniqueSlotIds.join(",");

    const groupedSlots = {};
    getSlots.forEach((slot) => {
      const date = moment(slot.date).format("DD-MMMM-YYYY");
      if (!groupedSlots[date]) {
        groupedSlots[date] = [];
      }
      groupedSlots[date].push(slot);
    });

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Book Coach Online
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {Object.entries(groupedSlots).map(([date, slots]) => (
              <div key={date}>
                <p className="selected_date_cart">{date}</p>
                {slots.map((slot, index) => (
                  <div key={index}>
                    <p className="selected_slots_cart">
                      {slot.start_time} - {slot.end_time}
                    </p>
                    {/* Additional slot information can be displayed here */}
                  </div>
                ))}
              </div>
            ))}
            {/* {getSelectedDate.map((date, index) => {
              dateIds.push(date._id);
            })}
            {console.log("the ids ", dateIds)} */}
            <div className="row">
              <div className="col-md-12">
                <h1 style={{ marginLeft: "41%", marginBottom: "5%" }}>
                  R {getOnlineDetail?.payment_status}
                </h1>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <form action={`${base_uri.payfast_uri}`} method="post">
            <input
              type="hidden"
              name="merchant_id"
              value={`${base_uri.merchant_id}`}
            />
            <input
              type="hidden"
              name="merchant_key"
              value={`${base_uri.merchant_key}`}
            />
            <input type="hidden" name="return_url" value={base_uri.url + "/"} />
            <input type="hidden" name="cancel_url" value={base_uri.url} />
            <input
              type="hidden"
              name="notify_url"
              value={base_uri.backend_base_ngrok_uri + `/notify_url`}
            />
            <input type="hidden" name="m_payment_id" value={userDetails?._id} />
            <input
              type="hidden"
              name="amount"
              value={getOnlineDetail?.payment_status}
            />
            <input
              type="hidden"
              name="item_name"
              value={getOnlineDetail?.class_type}
            />
            <input
              type="hidden"
              name="custom_str1"
              value={
                param?.course_id + "," + classBookingType + "," + subjectDetail
              }
            />
            <input
              type="hidden"
              name="custom_str2"
              value={getOnlineDetail?.coach_id}
            />
            <input type="hidden" name="custom_str3" value={timeSlotId} />
            <input type="hidden" name="custom_str4" value={SlotId} />
            <input
              type="hidden"
              name="custom_str5"
              value={getSlots.timeSlotId}
            />
            <input type="hidden" name="custom_int1" value={getClass} />
            <input
              type="hidden"
              name="passphrase"
              value={`${base_uri.passphrase}`}
            />

            {/* <br></br> */}
            <div className="row">
              <div className="col-md-12">
                <button
                  type="submit"
                  style={{
                    width: "200px",
                    height: "49px",
                    marginLeft: "260px",
                    color: "white",
                    backgroundColor: "blue",
                    border: "none",
                  }}
                >
                  {" "}
                  Pay
                </button>
              </div>
            </div>
          </form>
        </Modal.Footer>
      </Modal>
    );
  }
  // Ends

  const getApi = (class_type) => {
    setLoader1(true);

    getCoachTimeSlotv2(param.id).then((res) => {
      if (res.data.response.status == 0) {
        Store.addNotification({
          title: "Error!",
          message: res?.data?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        localStorage.removeItem("auth_token");
        localStorage.removeItem("state");
        navigate("/login");
      }
      setLoader(false);
      setLoader1(false);
      setTimeSlot(res?.data?.response);
      console.log("---gettimeSlot", res?.data?.response);
      const subjects = getTimeSlot
        .flatMap((entry) => entry.slot.map((slot) => slot.subject))
        .filter((subject) => subject);
      const uniqueSubjects = [
        ...new Set(
          getTimeSlot
            .flatMap((entry) => entry.slot.map((slot) => slot.subject))
            .filter((subject) => subject)
        ),
      ];
      console.log("res----->>>>>", res.data.response);
      // setSubjectList(uniqueSubjects);

      // setSubjectList([
      //   ...new Set(
      //     res?.data?.response
      //       .flatMap((entry) => entry.slot.map((slot) => slot.subject))
      //       .filter((subject) => subject)
      //   ),
      // ]);

      // console.log("Subjects -------", [
      //   ...new Set(
      //     res?.data?.response
      //       .flatMap((entry) => entry.slot.map((slot) => slot.subject))
      //       .filter((subject) => subject)
      //   ),
      // ]);
    });
  };
  const getDateFunc = (date) => {
    let exists = false;
    if (date) {
      for (let i of getTimeSlot) {
        if (
          moment(i.select_date)?.format("YYYY-MM-DD") ==
          moment(date)?.format("YYYY-MM-DD")
        ) {
          setSelectedSlot(i);
          console.log("----------i", i);
          exists = true;
        }
      }
      if (!exists) {
        setSelectedSlot(null);
      }
    }
  };
  useEffect(() => {
    getSingleCoach(param.id)
      .then((res) => {
        console.log("Coach prod=====>", res.data.response);
        setLoader(false);
        setCoach1(res.data.response);
        let lng = new Set([]);
        for (let l of res.data.response?.myCourse
          .map((v) => v?.language)
          .filter((v) => v != "")) {
          lng.add(l);
        }
        // setSubject(res.data.response.subject_of_teacher);

        setSubjectList(res.data.response.subject_of_teacher);
        setGrade(res.data.response.grade);
        setService(res.data.response.service_offered);
      })
      .catch((err) => {
        setLoader(false);
        // console.log(err);
      });
    console.log("Here are my params");
    console.log(param.id);
    console.log(param.course_id);
    getCoachTimeSlotv2(param.id).then((res) => {
      console.log("GETTING COACH TIMESLOT");
      if (res.data.response.status == 0) {
        Store.addNotification({
          title: "Error!",
          message: res?.data?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        localStorage.removeItem("auth_token");
        localStorage.removeItem("state");
        navigate("/login");
      }
      setLoader1(false);
      console.log("---gettimeSlot", res?.data?.response);

      setTimeSlot(res?.data?.response);

      const subjects = getTimeSlot
        .flatMap((entry) => entry.slot.map((slot) => slot.subject))
        .filter((subject) => subject);

      const uniqueSubjects = [...new Set(subjects)];
      // setSubjectList(uniqueSubjects);

      console.log("uniqueSubjects -------", uniqueSubjects);
      console.log("Subjects -------", subjectList);
      setLoader(false);
      setLoader1(false);
    });

    profile().then((res) => {
      setUserDetails(res.data.response);
    });
  }, [getCoachTimeSlotv2]);

  // Methods for opening in new windows/tabs
  const primaryForm = () => {
    window.open("https://forms.office.com/r/pFtRAnpHw5", "_blank");
  };

  const secForm = () => {
    window.open("https://forms.office.com/r/MLKEFkDsBA", "_blank");
  };
  // *****************************************

  // Methods for rounding digits
  const roundToTwoPlaces = (num) => {
    const factor = 10 ** 2;
    return Math.round(num * factor) / factor;
  };

  const roundToDefinedPlaces = (num, places) => {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };
  // ***************************************

  return (
    <>
      <style>{css}</style>

      <Header />
      {getLoader === true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getLoader}
        >
          <Spinner animation="grow" />
        </Backdrop>
      ) : (
        <>
          <div className="container pt-120">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <i className="fas fa-chevron-right"></i>
              <li className="breadcrumb-item active">Course</li>
              <i className="fas fa-chevron-right"></i>
              <li className="breadcrumb-item active">Book Coach</li>
            </ul>
            <div className="book-caoch-top">
              <div className="book-coach-top-left">
                <div className="book-coach-top-left-title">
                  <div
                    className="coach-photo wow fadeInUp"
                    data-wow-duration=".5s"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: " 0.4s",
                    }}
                  >
                    <img src={getCoach1?.coach_image} />
                    <h3>
                      <Link to={`/coachprofile/${getCoach1?._id}`}>
                        {getCoach1?.coach_name}
                      </Link>
                    </h3>
                    {/* <ul className='bookleft-coach-de'>
                      <li>
                        <div>Grade :</div>
                        {getCoach1?.grade?.length > 0
                          ? getCoach1?.grade?.map((i, k) => {
                              return <span key={k}>{i}</span>;
                            })
                          : 'Not Found'}
                      </li>
                    </ul> */}
                  </div>
                  <div
                    className=" wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: " 0.4s",
                    }}
                  >
                    {/* <h3 className="course-category">Maths</h3> */}
                    <h2 className="course-title">{getCoach1?.coach_name}</h2>
                    <p className="course-top-para">
                      {getDetail?.enrolledStudent} Enrolled Student
                    </p>
                    <h5>
                      <span>Book your multiple slots</span>
                    </h5>
                    <div
                      className="pricing-wrapper start-course-desc wow fadeInUp"
                      data-wow-duration="1.5s"
                      data-wow-delay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: " 0.4s",
                      }}
                    >
                      <ul className="nav   pricing-btn">
                        <li>
                          <button
                            className="joinonlineclas cmn-bk-coc"
                            data-bs-toggle="tab"
                            data-bs-target="#monthly"
                            style={{
                              color: getActive == "online" ? "blue" : "",
                            }}
                            onClick={() => {
                              setSelectedSlot(null);
                              setStudyDetail(null);
                              setPrice(0);
                              setActive("online");
                              getApi("online");
                              setValue([]);
                              setClass(null);
                              setOfflineDetail(null);
                            }}
                          >
                            Online
                          </button>
                        </li>
                        <li>
                          <button
                            className="boononeone cmn-bk-coc"
                            data-bs-toggle="tab"
                            data-bs-target="#annual"
                            style={{
                              color: getActive == "one-to-one" ? "blue" : "",
                            }}
                            onClick={() => {
                              setSelectedSlot(null);
                              setStudyDetail(null);
                              setPrice(0);
                              setActive("one-to-one");
                              getApi("one-to-one");
                              setValue([]);
                              setClass(null);
                            }}
                          >
                            Face to Face
                          </button>
                        </li>
                      </ul>

                      {/* Offline Slot */}
                      <div className="tab-content book-coacnew-top">
                        <div className="tab-pane fade" id="annual">
                          {getLoader1 == true || getLoader === true ? (
                            <>
                              <Box sx={{ width: "100%" }}>
                                <LinearProgress />
                              </Box>
                            </>
                          ) : (
                            <div
                              className="select-date-container wow fadeInUp"
                              data-wow-duration="1s"
                              data-wow-delay=".2s"
                              style={{
                                visibility: "visible",
                                animationDuration: "1s",
                                animationDelay: " 0.4s",
                              }}
                            >
                              <Formik
                                // enableReinitialize={true}
                                initialValues={{
                                  coach_id: "",
                                  select_date: "",
                                  grade: "",
                                  slot_time: [],
                                  class_type: "",
                                  payment_status: "",
                                  student_location: "",
                                  study_type: "",
                                }}
                                validationSchema={Yup.object({
                                  grade: Yup.string().required(),
                                  select_date: Yup.string().required(),
                                  // student_location: Yup.string().required(),
                                })}
                                validate={(values) => {
                                  const errors = {};
                                  if (
                                    getStudentLocation == "" &&
                                    getStudyDetail == "home_tution"
                                  ) {
                                    errors.student_location =
                                      "student_location is required!";
                                  }
                                  return errors;
                                }}
                                onSubmit={(values, { resetForm }) => {
                                  values.select_date = moment(
                                    values.select_date
                                  ).format("YYYY-MM-DD");
                                  values.slot_time = getValue || [];
                                  values.coach_id = getCoach1?._id;
                                  values.student_location = getStudentLocation;
                                  values.class_type = "one-to-one";
                                  values.payment_status = getPrice.toString();
                                  values.study_type = getStudyDetail.toString();
                                  if (!values.student_location)
                                    delete values.student_location;
                                  setTimeSlotId(getSelectedSlot?._id);
                                  setOfflineDetail(values);
                                  setButton(true);
                                }}
                              >
                                {(props) => (
                                  // <Form>
                                  //   {/* Additional Done */}
                                  //   <div>
                                  //     <label>Coach Address</label>
                                  //     <h5>{getCoach1?.coach_address}</h5>
                                  //     <br></br>
                                  //   </div>
                                  //   <div className='select-className-book-coach'>
                                  //     <div className='form-group'>
                                  //       <label>Select Grade</label>
                                  //       <select
                                  //         name='grade'
                                  //         onChange={(e) => {
                                  //           setPrice(0);
                                  //           setValue([]);
                                  //           setClass(e.target.value);

                                  //           props.values.grade = e.target.value;
                                  //         }}
                                  //       >
                                  //         <option value=''>
                                  //           Select Option
                                  //         </option>
                                  //         {getCoach1?.grade?.length > 0
                                  //           ? getCoach1?.grade?.map((i, k) => {
                                  //               return (
                                  //                 <option value={i} key={k}>
                                  //                   {i}
                                  //                 </option>
                                  //               );
                                  //             })
                                  //           : ""}
                                  //       </select>
                                  //       {props.errors.grade &&
                                  //         props.touched.grade && (
                                  //           <div style={{ color: "red" }}>
                                  //             {props.errors.grade}
                                  //           </div>
                                  //         )}
                                  //     </div>
                                  //   </div>

                                  //   {/* aditya */}

                                  //   {getClass ? (
                                  //     subjectList.length > 0 ? (
                                  //       <div className='select-className-book-coach'>
                                  //         <div className='form-group'>
                                  //           <label>Select Subject</label>
                                  //           <select
                                  //             name='grade'
                                  //             onChange={(e) => {
                                  //               setSelectedSubject(
                                  //                 e.target.value
                                  //               );
                                  //               setSubjectDetail(
                                  //                 e.target.value
                                  //               );
                                  //               // props.values.grade = e.target.value;
                                  //             }}
                                  //           >
                                  //             <option value='0'>
                                  //               Select Option
                                  //             </option>
                                  //             {subjectList.map((i, k) => {
                                  //               return (
                                  //                 <option value={i} key={k}>
                                  //                   {i}
                                  //                 </option>
                                  //               );
                                  //             })}
                                  //           </select>
                                  //           {/* {props.errors.grade &&
                                  //       props.touched.grade && (
                                  //         <div style={{ color: 'red' }}>
                                  //           {props.errors.grade}
                                  //         </div>
                                  //       )} */}
                                  //         </div>
                                  //       </div>
                                  //     ) : null
                                  //   ) : (
                                  //     ""
                                  //   )}

                                  //   {/* aditya */}
                                  //   {/* Ends */}

                                  //   {getClass ? (
                                  //     <div className='form-group'>
                                  //       <label>
                                  //         Select Where you want to study
                                  //       </label>
                                  //       <select
                                  //         name='study_type'
                                  //         onChange={(v) => {
                                  //           setStudentLocation("");
                                  //           setStudyDetail(null);
                                  //           setPrice(0);
                                  //           setValue([]);
                                  //           props.values.study_type =
                                  //             v.target.value;
                                  //           setStudyDetail(v.target.value);
                                  //         }}
                                  //       >
                                  //         <option value='0'>
                                  //           Choose any option
                                  //         </option>
                                  //         {/* <option value='home_tution'>
                                  //           Home Tution
                                  //         </option> */}
                                  //         <option value='ethuta_center'>
                                  //           Ethuta Center
                                  //         </option>
                                  //       </select>
                                  //       {props.errors.study_type &&
                                  //         props.touched.study_type && (
                                  //           <div style={{ color: "red" }}>
                                  //             {props.errors.study_type}
                                  //           </div>
                                  //         )}
                                  //     </div>
                                  //   ) : (
                                  //     ""
                                  //   )}

                                  //   {console.log(
                                  //     "got Time Slot=>",
                                  //     getTimeSlot,
                                  //     "----",
                                  //     getClass,
                                  //     "----=-===",
                                  //     getStudyDetail,
                                  //     "------------",
                                  //     subjectDetail
                                  //   )}

                                  //   {getClass &&
                                  //   selectedSubject &&
                                  //   getStudyDetail != 0 &&
                                  //   subjectDetail != 0 ? (
                                  //     <>
                                  //       {getStudyDetail == "home_tution" ||
                                  //       getStudyDetail == "ethuta_center" ? (
                                  //         <>
                                  //           <div className='form-group'>
                                  //             <label>Select Date</label>
                                  //             <Calendar
                                  //               name='select_date'
                                  //               tileClassName={({
                                  //                 date,
                                  //                 view,
                                  //               }) => {
                                  //                 if (
                                  //                   getTimeSlot.find(
                                  //                     (x) =>
                                  //                       moment(
                                  //                         x?.select_date
                                  //                       )?.format(
                                  //                         "YYYY-MM-DD"
                                  //                       ) ==
                                  //                         moment(date).format(
                                  //                           "YYYY-MM-DD"
                                  //                         ) &&
                                  //                       x.slot.find(
                                  //                         (q) => !q.booked
                                  //                       )
                                  //                   )
                                  //                 ) {
                                  //                   return "content";
                                  //                 }
                                  //               }}
                                  //               minDate={new Date()}
                                  //               onChange={(e) => {
                                  //                 setValue([]);
                                  //                 setPrice(0);
                                  //                 if (e) {
                                  //                   getDateFunc(e);
                                  //                 }
                                  //                 props.values.select_date = e;
                                  //               }}
                                  //             />
                                  //             {props.errors.select_date &&
                                  //               props.touched.select_date && (
                                  //                 <div style={{ color: "red" }}>
                                  //                   {props.errors.select_date}
                                  //                 </div>
                                  //               )}
                                  //           </div>
                                  //           {getSelectedSlot &&
                                  //           getStudyDetail ==
                                  //             "ethuta_center" ? (
                                  //             <div className='form-group'>
                                  //               {/* Ethuat Center */}
                                  //               {getSelectedSlot?.slot?.find(
                                  //                 (q) =>
                                  //                   q.class == getClass &&
                                  //                   q.center_booking ==
                                  //                     "ethuta_center"
                                  //               ) ? (
                                  //                 <label>Select time</label>
                                  //               ) : (
                                  //                 <h4>No Class Found </h4>
                                  //               )}

                                  //               <div className='time-container'>
                                  //                 {getSelectedSlot?.slot
                                  //                   ?.length > 0
                                  //                   ? getSelectedSlot?.slot
                                  //                       ?.filter(
                                  //                         (q) =>
                                  //                           q?.center_booking ==
                                  //                             getStudyDetail &&
                                  //                           q.center_request ==
                                  //                             true &&
                                  //                           q?.class == getClass
                                  //                       )
                                  //                       ?.map((item, index) => {
                                  //                         return (
                                  //                           <>
                                  //                             <div
                                  //                               className='seperate-time'
                                  //                               key={index}
                                  //                             >
                                  //                               <input
                                  //                                 type='checkbox'
                                  //                                 checked={getValue?.find(
                                  //                                   (q) =>
                                  //                                     q ==
                                  //                                     item?._id
                                  //                                 )}
                                  //                                 disabled={
                                  //                                   item?.selected ==
                                  //                                     false ||
                                  //                                   item?.booked ==
                                  //                                     true
                                  //                                 }
                                  //                                 onChange={(
                                  //                                   e
                                  //                                 ) => {
                                  //                                   props.values.slot_time =
                                  //                                     item?._id;
                                  //                                   let existsIndex =
                                  //                                     getValue.findIndex(
                                  //                                       (v) =>
                                  //                                         v ==
                                  //                                         item?._id
                                  //                                     );

                                  //                                   if (
                                  //                                     existsIndex <
                                  //                                     0
                                  //                                   ) {
                                  //                                     let dataIndex =
                                  //                                       getSelectedSlot.slot.findIndex(
                                  //                                         (q) =>
                                  //                                           q._id ==
                                  //                                           item?._id
                                  //                                       );
                                  //                                     setValue([
                                  //                                       ...getValue,
                                  //                                       getSelectedSlot
                                  //                                         ?.slot[
                                  //                                         dataIndex
                                  //                                       ]?._id,
                                  //                                     ]);
                                  //                                     setPrice(
                                  //                                       Number(
                                  //                                         getSelectedSlot?.coach_booking_price
                                  //                                       ) +
                                  //                                         Number(
                                  //                                           getPrice
                                  //                                         )
                                  //                                     );
                                  //                                   } else {
                                  //                                     getValue.splice(
                                  //                                       existsIndex,
                                  //                                       1
                                  //                                     );
                                  //                                     setPrice(
                                  //                                       Number(
                                  //                                         getPrice
                                  //                                       ) -
                                  //                                         Number(
                                  //                                           getSelectedSlot?.coach_booking_price
                                  //                                         )
                                  //                                     );
                                  //                                     setValue([
                                  //                                       ...getValue,
                                  //                                     ]);
                                  //                                   }
                                  //                                 }}
                                  //                               />
                                  //                               <span>
                                  //                                 {item?.selected ==
                                  //                                 false ? (
                                  //                                   <span
                                  //                                     style={{
                                  //                                       backgroundColor:
                                  //                                         "#9c8e8e",
                                  //                                     }}
                                  //                                   >
                                  //                                     {
                                  //                                       item?.time_slot
                                  //                                     }{" "}
                                  //                                     -{" "}
                                  //                                     {
                                  //                                       item?.end_time
                                  //                                     }
                                  //                                   </span>
                                  //                                 ) : item?.booked ==
                                  //                                   true ? (
                                  //                                   <span
                                  //                                     style={{
                                  //                                       backgroundColor:
                                  //                                         "red",
                                  //                                       color:
                                  //                                         "white",
                                  //                                     }}
                                  //                                   >
                                  //                                     {
                                  //                                       item?.time_slot
                                  //                                     }{" "}
                                  //                                     -
                                  //                                     {
                                  //                                       item?.end_time
                                  //                                     }
                                  //                                   </span>
                                  //                                 ) : (
                                  //                                   `${item?.time_slot}-${item?.end_time}`
                                  //                                 )}
                                  //                               </span>

                                  //                               <div className='timeHover'>
                                  //                                 <h3>
                                  //                                   Center
                                  //                                   Address
                                  //                                 </h3>
                                  //                                 <h4>
                                  //                                   {
                                  //                                     item
                                  //                                       ?.center_id
                                  //                                       ?.center_address
                                  //                                   }
                                  //                                 </h4>
                                  //                                 <h3>Class</h3>
                                  //                                 <h4>
                                  //                                   {
                                  //                                     item?.class
                                  //                                   }
                                  //                                 </h4>
                                  //                                 <h3>
                                  //                                   Subject
                                  //                                 </h3>
                                  //                                 <h4>
                                  //                                   {
                                  //                                     item?.subject
                                  //                                   }
                                  //                                 </h4>
                                  //                               </div>
                                  //                             </div>
                                  //                           </>
                                  //                         );
                                  //                       })
                                  //                   : ""}
                                  //               </div>
                                  //             </div>
                                  //           ) : (
                                  //             ""
                                  //           )}
                                  //           {/* Ends */}

                                  //           {/* Home Tution */}
                                  //           {getSelectedSlot &&
                                  //           getStudyDetail == "home_tution" ? (
                                  //             <div className='form-group'>
                                  //               {/* Ethuat Center */}
                                  //               {getSelectedSlot?.slot?.find(
                                  //                 (q) =>
                                  //                   q.class == getClass &&
                                  //                   q.center_booking ==
                                  //                     "home_tution"
                                  //               ) ? (
                                  //                 <label>Select time</label>
                                  //               ) : (
                                  //                 <h4>No Class Found :(</h4>
                                  //               )}
                                  //               <div className='time-container'>
                                  //                 {getSelectedSlot?.slot
                                  //                   ?.length > 0
                                  //                   ? getSelectedSlot?.slot
                                  //                       ?.filter(
                                  //                         (q) =>
                                  //                           q?.center_booking ==
                                  //                             getStudyDetail &&
                                  //                           q?.class ==
                                  //                             getClass &&
                                  //                           q.subject ==
                                  //                             selectedSubject
                                  //                       )
                                  //                       ?.map((item, index) => {
                                  //                         return (
                                  //                           <>
                                  //                             <div
                                  //                               className='seperate-time'
                                  //                               key={index}
                                  //                             >
                                  //                               <input
                                  //                                 type='checkbox'
                                  //                                 checked={getValue?.find(
                                  //                                   (q) =>
                                  //                                     q ==
                                  //                                     item?._id
                                  //                                 )}
                                  //                                 disabled={
                                  //                                   item?.selected ==
                                  //                                     false ||
                                  //                                   item?.booked ==
                                  //                                     true
                                  //                                 }
                                  //                                 onChange={(
                                  //                                   e
                                  //                                 ) => {
                                  //                                   props.values.slot_time =
                                  //                                     item?._id;
                                  //                                   let existsIndex =
                                  //                                     getValue.findIndex(
                                  //                                       (v) =>
                                  //                                         v ==
                                  //                                         item?._id
                                  //                                     );

                                  //                                   if (
                                  //                                     existsIndex <
                                  //                                     0
                                  //                                   ) {
                                  //                                     let dataIndex =
                                  //                                       getSelectedSlot.slot.findIndex(
                                  //                                         (q) =>
                                  //                                           q._id ==
                                  //                                           item?._id
                                  //                                       );
                                  //                                     setValue([
                                  //                                       ...getValue,
                                  //                                       getSelectedSlot
                                  //                                         ?.slot[
                                  //                                         dataIndex
                                  //                                       ]?._id,
                                  //                                     ]);
                                  //                                     setPrice(
                                  //                                       Number(
                                  //                                         getSelectedSlot?.coach_booking_price
                                  //                                       ) +
                                  //                                         Number(
                                  //                                           getPrice
                                  //                                         )
                                  //                                     );
                                  //                                   } else {
                                  //                                     getValue.splice(
                                  //                                       existsIndex,
                                  //                                       1
                                  //                                     );
                                  //                                     setPrice(
                                  //                                       Number(
                                  //                                         getPrice
                                  //                                       ) -
                                  //                                         Number(
                                  //                                           getSelectedSlot?.coach_booking_price
                                  //                                         )
                                  //                                     );
                                  //                                     setValue([
                                  //                                       ...getValue,
                                  //                                     ]);
                                  //                                   }
                                  //                                 }}
                                  //                               />

                                  //                               <span>
                                  //                                 {item?.selected ==
                                  //                                 false ? (
                                  //                                   <span
                                  //                                     style={{
                                  //                                       backgroundColor:
                                  //                                         "#9c8e8e",
                                  //                                     }}
                                  //                                   >
                                  //                                     {
                                  //                                       item?.time_slot
                                  //                                     }{" "}
                                  //                                     -{" "}
                                  //                                     {
                                  //                                       item?.end_time
                                  //                                     }
                                  //                                   </span>
                                  //                                 ) : item?.booked ==
                                  //                                   true ? (
                                  //                                   <span
                                  //                                     style={{
                                  //                                       backgroundColor:
                                  //                                         "red",
                                  //                                       color:
                                  //                                         "white",
                                  //                                     }}
                                  //                                   >
                                  //                                     {
                                  //                                       item?.time_slot
                                  //                                     }{" "}
                                  //                                     -
                                  //                                     {
                                  //                                       item?.end_time
                                  //                                     }
                                  //                                   </span>
                                  //                                 ) : (
                                  //                                   `${item?.time_slot}-${item?.end_time}`
                                  //                                 )}
                                  //                               </span>
                                  //                               <div className='timeHover'>
                                  //                                 <h3>
                                  //                                   Coach
                                  //                                   Address
                                  //                                 </h3>
                                  //                                 <h4>
                                  //                                   {
                                  //                                     getDetail
                                  //                                       ?.coach
                                  //                                       ?.coach_address
                                  //                                   }
                                  //                                 </h4>
                                  //                                 <h3>Class</h3>
                                  //                                 <h4>
                                  //                                   {
                                  //                                     item?.class
                                  //                                   }
                                  //                                 </h4>
                                  //                                 <h3>
                                  //                                   Subject
                                  //                                 </h3>
                                  //                                 <h4>
                                  //                                   {
                                  //                                     item?.subject
                                  //                                   }
                                  //                                 </h4>
                                  //                               </div>
                                  //                             </div>
                                  //                           </>
                                  //                         );
                                  //                       })
                                  //                   : ""}
                                  //               </div>
                                  //             </div>
                                  //           ) : (
                                  //             ""
                                  //           )}
                                  //           {/* Ends */}
                                  //         </>
                                  //       ) : (
                                  //         <h4>please select study type </h4>
                                  //       )}
                                  //     </>
                                  //   ) : (
                                  //     <h4>Please select grade & Subject</h4>
                                  //   )}
                                  //   {console.log(
                                  //     "/* Home Tution */ getClass---",
                                  //     getClass,
                                  //     "got classBookingType=>",
                                  //     classBookingType,
                                  //     "---- getStudyDetail",
                                  //     getStudyDetail,
                                  //     "------------ subjectDetail",
                                  //     subjectDetail
                                  //   )}
                                  //   {getPrice &&
                                  //   getStudyDetail == "home_tution" ? (
                                  //     <>
                                  //       <label>
                                  //         (*Note please enter your address but
                                  //         remember it is not far from coach
                                  //         address)
                                  //       </label>
                                  //       <input
                                  //         type='text'
                                  //         name='student_location'
                                  //         onChange={(e) => {
                                  //           // console.log(e.target.value);
                                  //           setStudentLocation(e.target.value);
                                  //           props.values.student_location =
                                  //             e.target.value;
                                  //         }}
                                  //       />
                                  //       {props.errors.student_location &&
                                  //         props.touched.student_location && (
                                  //           <div style={{ color: "red" }}>
                                  //             {props.errors.student_location}
                                  //           </div>
                                  //         )}
                                  //     </>
                                  //   ) : (
                                  //     ""
                                  //   )}
                                  //   {getPrice && getStudyDetail != "0" ? (
                                  //     <div>
                                  //       <h5 style={{ marginBottom: "20px" }}>
                                  //         Purchase Cost home tutuion:-{" "}
                                  //         {getSelectedSlot?.currency_type}{" "}
                                  //         {getPrice}
                                  //       </h5>
                                  //     </div>
                                  //   ) : (
                                  //     ""
                                  //   )}

                                  //   {button === false ? (
                                  //     getValue?.length > 0 &&
                                  //     getPrice &&
                                  //     getSelectedSlot ? (
                                  //       <button
                                  //         className='btn btn-primary btn-hover-heading-color cmn-btn'
                                  //         type='submit'
                                  //       >
                                  //         Book Coach
                                  //       </button>
                                  //     ) : (
                                  //       ""
                                  //     )
                                  //   ) : (
                                  //     <button
                                  //       className='btn btn-primary btn-hover-heading-color cmn-btn'
                                  //       type='button'
                                  //     >
                                  //       wait please! :)
                                  //     </button>
                                  //   )}
                                  // </Form>
                                  <Form>
                                    <p>
                                      <b>
                                        {" "}
                                        Click here to book primary school one on
                                        one session{" "}
                                      </b>
                                      <span>
                                        <button
                                          onClick={primaryForm}
                                          className="btn btn-primary btn-hover-heading-color cmn-btn"
                                          style={{
                                            width: "80px",
                                            height: "20px",
                                            fontSize: "smaller",
                                            padding: "0px",
                                            lineHeight: "16px",
                                          }}
                                        >
                                          Click here
                                        </button>
                                      </span>
                                    </p>
                                    <br></br>

                                    <span>
                                      <b>
                                        {" "}
                                        Book your face to face high school
                                        tutoring session{" "}
                                      </b>
                                    </span>
                                    <button
                                      onClick={primaryForm}
                                      className="btn btn-primary btn-hover-heading-color cmn-btn"
                                      style={{
                                        width: "80px",
                                        height: "20px",
                                        fontSize: "smaller",
                                        padding: "0px",
                                        lineHeight: "16px",
                                      }}
                                    >
                                      Click here
                                    </button>
                                  </Form>
                                )}
                              </Formik>
                            </div>
                          )}
                        </div>

                        <div className="tab-pane fade show active" id="monthly">
                          {getLoader1 == true ? (
                            <>
                              <Box sx={{ width: "100%" }}>
                                <LinearProgress />
                              </Box>
                            </>
                          ) : (
                            <div
                              className="select-date-container wow fadeInUp"
                              data-wow-duration="1s"
                              data-wow-delay=".2s"
                              style={{
                                visibility: "visible",
                                animationDuration: "1s",
                                animationDelay: " 0.4s",
                              }}
                            >
                              {/*  Online Booking */}

                              <Formik
                                initialValues={{
                                  coach_id: "",
                                  select_date: "",
                                  grade: "",
                                  slot_time: [],
                                  class_type: "",
                                  payment_status: "",
                                  booked_for: "",
                                }}
                                validationSchema={Yup.object({
                                  grade: Yup.string().required(),
                                  select_date: Yup.string().required(),
                                })}
                                onSubmit={(values, { resetForm }) => {
                                  values.slot_time = getValue || [];
                                  values.coach_id = getCoach1?._id;
                                  values.class_type = "online";
                                  values.payment_status = getPrice.toString();
                                  values.select_date = moment(
                                    values.select_date
                                  ).format("YYYY-MM-DD");
                                  values.booked_for = classBookingType;
                                  setTimeSlotId(
                                    getSelectedSlot?.date_reference_id
                                  );
                                  setOnlineDetail(values);

                                  setButton(true);
                                  setOnlineShow(true);
                                  setButton(true);
                                }}
                              >
                                {(props) => (
                                  <Form>
                                    <div className="select-className-book-coach">
                                      <div className="form-group">
                                        <label>Select Grade</label>
                                        <select
                                          name="grade"
                                          onChange={(e) => {
                                            setPrice(0);
                                            setClass(e.target.value);
                                            setValue([]);
                                            props.values.grade = e.target.value;
                                          }}
                                        >
                                          <option value="0">
                                            Select Option
                                          </option>
                                          {getCoach1?.grade?.length > 0
                                            ? getCoach1?.grade?.map((i, k) => {
                                                return (
                                                  <option value={i} key={k}>
                                                    {i}
                                                  </option>
                                                );
                                              })
                                            : ""}
                                        </select>
                                        {props.errors.grade &&
                                          props.touched.grade && (
                                            <div style={{ color: "red" }}>
                                              {props.errors.grade}
                                            </div>
                                          )}
                                      </div>
                                    </div>

                                    {getClass ? (
                                      subjectList.length > 0 ? (
                                        <div className="select-className-book-coach">
                                          <div className="form-group">
                                            <label>Select Subject</label>
                                            <select
                                              name="grade"
                                              onChange={(e) => {
                                                setSelectedSubject(
                                                  e.target.value
                                                );
                                                setSubjectDetail(
                                                  e.target.value
                                                );
                                                // props.values.grade = e.target.value;
                                              }}
                                            >
                                              <option value="0">
                                                Select Option
                                              </option>
                                              {subjectList.map((i, k) => {
                                                return (
                                                  <option value={i} key={k}>
                                                    {i}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </div>
                                        </div>
                                      ) : null
                                    ) : (
                                      ""
                                    )}

                                    {getClass != "0" && selectedSubject ? (
                                      <div className="select-className-book-coach">
                                        <div className="form-group">
                                          <label>Type Of Class</label>

                                          <select
                                            name="booked_for"
                                            onChange={(e) => {
                                              setClassBookingType(
                                                e.target.value
                                              );
                                              console.log(e.target.value);
                                            }}
                                          >
                                            <option value="0">
                                              Select Option
                                            </option>

                                            <option
                                              value="onetoone"
                                              key="onetoone"
                                            >
                                              One To One
                                            </option>

                                            {/* <option
                                              value='onetomany'
                                              key='onetomany'
                                            >
                                              One To Many
                                            </option> */}
                                          </select>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {getClass != "0" &&
                                    selectedSubject &&
                                    classBookingType ? (
                                      <>
                                        <div className="form-group">
                                          <label>Select Date--</label>
                                          <Calendar
                                            name="select_date"
                                            tileClassName={({ date, view }) => {
                                              if (
                                                getTimeSlot.find(
                                                  (x) =>
                                                    moment(
                                                      x?.select_date
                                                    )?.format("YYYY-MM-DD") ==
                                                      moment(date).format(
                                                        "YYYY-MM-DD"
                                                      ) &&
                                                    x.slot.find(
                                                      (q) => !q.booked
                                                    )
                                                )
                                              ) {
                                                return "content";
                                              }
                                            }}
                                            minDate={new Date()}
                                            onChange={(e) => {
                                              getDateFunc(e);
                                              // setValue([]);
                                              // setPrice(0);

                                              props.values.select_date = e;
                                              // console.log("I selected date ",getValue);
                                            }}
                                          />
                                          {props.errors.select_date &&
                                            props.touched.select_date && (
                                              <div style={{ color: "red" }}>
                                                {props.errors.select_date}
                                              </div>
                                            )}
                                        </div>
                                      </>
                                    ) : (
                                      <h4>please select Grade & Subject</h4>
                                    )}
                                    {console.log(
                                      "/* ONLINE */---- getClass=>",
                                      getClass,
                                      "---- classBookingType=>",
                                      classBookingType,
                                      "---- subjectDetail=>",
                                      subjectDetail
                                    )}
                                    {getClass != "0" ? (
                                      <>
                                        {getSelectedSlot ? (
                                          <div className="form-group">
                                            {/* Not To Show Select Time */}
                                            {getSelectedSlot?.slot?.find(
                                              (q) =>
                                                (!q.booked &&
                                                  q.selected &&
                                                  q.booked_for !==
                                                    "onetoone") ||
                                                (!q.booked &&
                                                  q.selected &&
                                                  q.bought_status === "pending")
                                            ) ? (
                                              <label>Select time </label>
                                            ) : (
                                              <h4>No Class Found :(</h4>
                                            )}

                                            {/* No Class Match */}
                                            <div className="time-container">
                                              {getSelectedSlot?.slot?.length > 0
                                                ? getSelectedSlot?.slot
                                                    ?.filter(
                                                      (q) =>
                                                        (!q.booked &&
                                                          q.selected &&
                                                          q.booked_for !==
                                                            "onetoone") ||
                                                        (!q.booked &&
                                                          q.selected &&
                                                          q.bought_status ===
                                                            "pending")
                                                    )
                                                    .map((item, index) => {
                                                      return (
                                                        <>
                                                          <div
                                                            className="seperate-time"
                                                            key={index}
                                                          >
                                                            <input
                                                              type="checkbox"
                                                              checked={getValue?.find(
                                                                (q) =>
                                                                  q == item?._id
                                                              )}
                                                              disabled={
                                                                item?.selected ==
                                                                false
                                                              }
                                                              onChange={(e) => {
                                                                props.values.slot_time =
                                                                  item?._id;

                                                                setSlots([
                                                                  ...getSlots,
                                                                  {
                                                                    start_time:
                                                                      item?.time_slot,
                                                                    end_time:
                                                                      item?.end_time,
                                                                    date: getSelectedSlot.select_date,
                                                                    timeSlotId:
                                                                      getSelectedSlot.date_reference_id,
                                                                    slotIds:
                                                                      item?.slot_reference_id,
                                                                  },
                                                                ]);
                                                                let existsIndex =
                                                                  getValue.findIndex(
                                                                    (v) =>
                                                                      v ==
                                                                      item?._id
                                                                  );

                                                                if (
                                                                  existsIndex <
                                                                  0
                                                                ) {
                                                                  let dataIndex =
                                                                    getSelectedSlot.slot.findIndex(
                                                                      (q) =>
                                                                        q._id ==
                                                                        item?._id
                                                                    );
                                                                  setValue([
                                                                    ...getValue,
                                                                    getSelectedSlot
                                                                      ?.slot[
                                                                      dataIndex
                                                                    ]?._id,
                                                                  ]);
                                                                  let price =
                                                                    roundToTwoPlaces(
                                                                      Number(
                                                                        getPrice
                                                                      ) +
                                                                        Number(
                                                                          getSelectedSlot?.coach_booking_price
                                                                        )
                                                                    );
                                                                  setPrice(
                                                                    price
                                                                  );
                                                                } else {
                                                                  setSlots(
                                                                    (
                                                                      prevSlots
                                                                    ) =>
                                                                      prevSlots.filter(
                                                                        (
                                                                          slot
                                                                        ) =>
                                                                          slot.start_time !==
                                                                            item?.time_slot &&
                                                                          slot.end_time !==
                                                                            item?.end_time
                                                                      )
                                                                  );
                                                                  getValue.splice(
                                                                    existsIndex,
                                                                    1
                                                                  );
                                                                  let price =
                                                                    roundToTwoPlaces(
                                                                      Number(
                                                                        getPrice
                                                                      ) -
                                                                        Number(
                                                                          getSelectedSlot?.coach_booking_price
                                                                        )
                                                                    );

                                                                  console.log(
                                                                    price
                                                                  );
                                                                  if (
                                                                    price < 0
                                                                  ) {
                                                                    setPrice(0);
                                                                  } else {
                                                                    setPrice(
                                                                      price
                                                                    );
                                                                  }
                                                                  setValue([
                                                                    ...getValue,
                                                                  ]);
                                                                }
                                                              }}
                                                            />

                                                            <span>
                                                              {item?.selected ==
                                                              false ? (
                                                                <span
                                                                  style={{
                                                                    backgroundColor:
                                                                      "#9c8e8e",
                                                                  }}
                                                                >
                                                                  {
                                                                    item?.time_slot
                                                                  }
                                                                </span>
                                                              ) : item?.booked ==
                                                                true ? (
                                                                <span
                                                                  style={{
                                                                    backgroundColor:
                                                                      "red",
                                                                    color:
                                                                      "white",
                                                                  }}
                                                                >
                                                                  {
                                                                    item?.time_slot
                                                                  }
                                                                </span>
                                                              ) : (
                                                                <>
                                                                  {
                                                                    item.time_slot
                                                                  }{" "}
                                                                  -{" "}
                                                                  {
                                                                    item.end_time
                                                                  }
                                                                </>
                                                              )}
                                                            </span>
                                                            {/* <div className='timeHover'>
                                                              <h3>Class</h3>
                                                              <h4>
                                                                {item?.class}
                                                              </h4>
                                                              <h3>Subject</h3>
                                                              <h4>
                                                                {item?.subject}
                                                              </h4>
                                                            </div> */}
                                                          </div>
                                                        </>
                                                      );
                                                    })
                                                : ""}
                                            </div>
                                          </div>
                                        ) : (
                                          <h4>No Slot Found :(</h4>
                                          // ''
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {getPrice ? (
                                      <div>
                                        <h5 style={{ marginBottom: "20px" }}>
                                          Purchase Cost:-{" "}
                                          {getSelectedSlot?.currency_type}{" "}
                                          {getPrice}
                                        </h5>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {getSelectedSlot && getValue?.length > 0 ? (
                                      <button
                                        className="btn btn-primary btn-hover-heading-color cmn-btn"
                                        type="submit"
                                      >
                                        Book Coach
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </Form>
                                )}
                              </Formik>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}

      {/* Offline */}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {/* Ends */}
      {/* Online */}
      <MyVerticallyCenteredModal1
        show={onlineShow}
        onHide={() => setOnlineShow(false)}
      />
      {/* Ends */}
    </>
  );
}
