import React from "react";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <>
      <div className="footer-section section">
        <div className="container">
          {/* <!-- Footer Widget Wrapper Start --> */}
          <div className="footer-widget-wrapper">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                {/* <!-- Footer Widget Start --> */}
                <div className="footer-widget">
                  <Link className="footer-logo " to="/">
                    <img src="/images/logo.webp" alt="Logo" />
                  </Link>
                  <div className="widget-info">
                    <p>
                      <i className="flaticon-phone-call"></i>
                      <a href="tel:+">011 238 7037</a>
                    </p>
                    <p>
                      <i className="flaticon-open-mail"></i>

                      <a href="">admin@ethuta.com</a>
                    </p>
                    <p>
                      <i className="flaticon-placeholder"></i>

                      <span>161 Allan Road Glen Austin Midrand</span>
                    </p>
                    <ul className="social">
                      <li>
                        <a href="https://www.facebook.com/people/Ethuta/100063904326846/">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/ethuta/">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/e_thuta
"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/ethuta1/">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.tiktok.com/@ethutacenter?lang=en ">
                          <i className="fab fa-tiktok"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://web.whatsapp.com/send?phone=076 287 0323">
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- Footer Widget End --> */}
              </div>
              <div className="col-lg-3 col-sm-6">
                {/* <!-- Footer Widget Start --> */}
                <div className="footer-widget">
                  <h4 className="footer-widget-title">Useful Links</h4>
                  <div className="widget-link">
                    <ul className="link">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      {localStorage?.getItem("auth_token") ? (
                        <li>
                          <Link to="/becomecoach">Become a Coach</Link>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
                {/* <!-- Footer Widget End --> */}
              </div>
              <div className="col-lg-3 col-sm-6">
                {/* <!-- Footer Widget Start --> */}
                <div className="footer-widget">
                  <h4 className="footer-widget-title">Quick Links</h4>
                  <div className="widget-link">
                    <ul className="link">
                      <li>
                        <Link to="/search_filter">Course</Link>
                      </li>
                      <li>
                        <Link to="/multicoach">Coach</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                      {/* <li>
                        <Link to="/mycourse">Books</Link>
                      </li>
                      <li>
                        <Link to='/mycourse'>Videos</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                {/* <!-- Footer Widget End --> */}
              </div>
              <div className="col-lg-3 col-sm-6">
                {/* <!-- Footer Widget Start --> */}
                <div className="footer-widget">
                  <h4 className="footer-widget-title">Terms</h4>
                  <div className="widget-link">
                    <ul className="link">
                      <li>
                        <a
                          href="https://ethutalearning.blob.core.windows.net/admincontainer/Clients platform terms & conditions  (1).pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learner Terms &amp; Condition
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://ethutalearning.blob.core.windows.net/admincontainer/Terms and Conditions for Tutors.odt"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Coach Terms &amp; Condition
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://ethutalearning.blob.core.windows.net/admincontainer/Standard Website Privacy and Cookies Policy.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy &amp; Policy
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://ethutalearning.blob.core.windows.net/admincontainer/PAIA_MANUAL_2023 updated (5).pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          PAIA Manual
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- Footer Widget End --> */}
              </div>
            </div>
          </div>
          {/* <!-- Footer Widget Wrapper End --> */}
          {/* <!-- Footer Copyright End --> */}
          <div className="footer-copyright text-center">
            <p>
              © Copyrights 2021 eThuta All rights reserved.{" "}
              <span>
                | Design &amp; Developed By{" "}
                <a href="https://www.ejoobi.com/" target="_blank">
                  ejoobi Tech
                </a>
              </span>{" "}
            </p>
          </div>
          {/* <!-- Footer Copyright End --> */}
        </div>
      </div>
      {/* <!-- Back to Top End --> */}
      {/* <button className="back-btn" id="backBtn">
        <i className="fa fa-angle-up"></i>
      </button> */}
      {/* <!-- Back to Top End --> */}
    </>
  );
}
