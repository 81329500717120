import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import InsideHeaderForAbout from "./InsideHeader/InsideHeaderForAbout";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  get_all_about_user,
  get_all_courses_video,
  getDashboard,
} from "../services/web/webServices";
import Backdrop from "@mui/material/Backdrop";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Header from "./Header";

export default function Blog() {
  const [getLoader, setLoader] = useState(true);
  const [getAboutUser, setAboutUser] = useState(null);
  const [getCourseVideo, setCourseVideo] = useState(null);
  const [getDetail, setDetail] = useState(null);
  const [userImage1, setUserImage1] = useState("");
  const [userImage2, setUserImage2] = useState("");
  const [userImage3, setUserImage3] = useState("");
  const [userImage4, setUserImage4] = useState("");
  const [userImage5, setUserImage5] = useState("");
  const [userImage6, setUserImage6] = useState("");

  useEffect(() => {
    get_all_about_user().then((res) => {
      setAboutUser(res.data.response);
      console.log("image--------", res.data.response[0].profile_pic);
      setUserImage1(res.data.response[0].profile_pic);
      setUserImage2(res.data.response[1].profile_pic);
      setUserImage3(res.data.response[2].profile_pic);
      setUserImage4(res.data.response[3].profile_pic);
      setUserImage5(res.data.response[4].profile_pic);
      setUserImage6(res.data.response[5].profile_pic);

      // setLoader(false);
    });
    get_all_courses_video().then((res) => {
      setCourseVideo(res.data.response);
    });

    getDashboard()
      .then((res) => {
        setLoader(false);
        setDetail(res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {/* <InsideHeaderForAbout /> */}
      <Header />
      {getLoader == true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getLoader}
        >
          <Spinner animation="grow" />
        </Backdrop>
      ) : (
        <>
          <div style={{ clear: "both" }}></div>
          <div className="about-top pt-120">
            <div className="container">
              <div className="abt-top-grid">
                <div className="about-top-left">
                  <h2
                    className="wow fadeInUp"
                    data-wow-duration="1s"
                    dataWowDelay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >	How to Keep Kids Motivated to Learn During the School Year
                  </h2>
                  <p
                    className="wow fadeInUp"
                    data-wow-duration="1s"
                    dataWowDelay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    We believe in partnering with parents to ensure their child
                    performs well in their academics
                  </p>
                  <ul>
                    <li
                      className="wow fadeInUp"
                      data-wow-duration="1s"
                      dataWowDelay=".2s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                      }}
                    >
                      <i className="fas fa-check-circle"></i> Qualified Tutors
                    </li>
                    <li
                      className="wow fadeInUp"
                      data-wow-duration="1s"
                      dataWowDelay=".3s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                      }}
                    >
                      <i className="fas fa-check-circle"></i> Quality Videos
                    </li>
                    <li
                      className="wow fadeInUp"
                      data-wow-duration="1s"
                      dataWowDelay=".4s"
                      style={{
                        visibility: "visible",
                        animationDuration: "1s",
                        animationDelay: "0.4s",
                      }}
                    >
                      <i className="fas fa-check-circle"></i> Affordable Price
                    </li>
                  </ul>
                </div>
                <div className="about-top-right">
                  <div
                    className="abt-top-images wow fadeInUp"
                    data-wow-duration="1s"
                    dataWowDelay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.4s",
                    }}
                  >
                    {/* <img src="images/eThuta_programs_2.png" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ clear: "both" }}></div>
          <div className="abt-counter">
            <div className="container">
              <div className="counter-fl">
                <div
                  className="wow fadeInUp"
                  data-wow-duration="1s"
                  dataWowDelay=".1s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                  }}
                >
                  <h2>
                    Total Student <span>700+</span>
                    {/* <span>{getDetail?.user}</span> */}
                  </h2>
                </div>
                <div
                  className=" wow fadeInUp"
                  data-wow-duration="1s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                  }}
                >
                  <h2>
                    Total Coach<span>50+</span>
                    {/* <span>{getDetail?.coach}+</span> */}
                  </h2>
                </div>
                <div
                  className=" wow fadeInUp"
                  data-wow-duration="1s"
                  dataWowDelay=".3s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                  }}
                >
                  <h2>
                    Total Courses<span>100+</span>
                    {/* <span>{getDetail?.course}+</span> */}
                  </h2>
                </div>
                <div
                  className=" wow fadeInUp"
                  data-wow-duration="1s"
                  dataWowDelay=".4s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                  }}
                >
                  <h2>
                    Total Videos<span>100+</span>
                    {/* <span>{getCourseVideo}+</span> */}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="howitwork container ht">
          <h2 style={
            {paddingBottom:"0"}
          }>OUR SERVICES
          </h2>
          <h2 >
          <Link
              to="/search_filter"
              className="global-button" style={{borderRadius:"15px", height:"3.5em"}}>
              Free Courses
            </Link>
          </h2>
            <div className="howitwork-grid1">
              <div
                className="ourservices-container1"
                // style={{
                //   paddingBottom: "40px",
                //   paddingTop: "50px",
                //   gap: "20px",
                //   paddingTop: "50px",
                //   width: "1300px",
                //   overflowX: "scroll",
                //   scrollBehavior: "smooth",
                // }}
              >
                
                <div
                  className="how it-work-section abt wow fadeInUp"
                  data-wow-duration=".5s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                    
                    
                    background: "rgb(241,241,239)",
                    transition: "background-color 0.3s ease",
                    cursor: "pointer",
                  }}
                >
                  <figure style={{ background: "rgb(14,132,219)" }}>
                    <img
                      src="images/OneOnOneTutorialGrade1to12.png"
                      alt="coach"
                      style={{ width: "210px ", height: " 40px" }}
                    />
                  </figure>
                  <h3
                    style={{
                      fontWeight: "bold",
                      
                      color: "rgb(14,132,219)",
                      textAlign: "center",
                    }}
                  >
                    One-On-One Tutoring(Grade 1-first year level):
                  </h3>
                  <p>
                    Our experienced tutors work closely with each learner,
                    customizing the learning journey to match unique learning
                    styles. We provide focused attention and a supportive
                    environment at home, at center or online.
                  </p>
                  <div className="combinebutton one-one">
                  <Link to="/multicoach" className="global-button startbtn"  style={{borderRadius:"15px", backgroundColor:"#0e84db", color:"white"}}>
                      Select a Tutor{" "}
                    </Link>
                  </div>
                </div>

                <div
                  className="how it-work-section abt wow fadeInUp"
                  data-wow-duration="1s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                    
                    
                    background: "rgb(241,241,239)",
                  }}
                >
                  <figure style={{ background: "rgb(126,217,87)" }}>
                    <img
                      src="images/HomeSchoolerTutoringProgramGrade1to9.png"
                      alt="coach"
                      style={{ width: "210px ", height: " 40px" }}
                    />
                  </figure>
                  <h3
                    style={{
                      fontWeight: "bold",
                      
                      color: "rgb(14,132,219)",
                      textAlign: "center",
                    }}
                  >
                    Homeschooler Tutoring Program (Grade 1-9)
                  </h3>
                  <p
                    style={{
                      color: "rgb(7,47,96)",
                     
                      
                      
                    }}
                  >
                    Flexible Program that allows you to bring curriculum that
                    best fit your child's needs. We provide individualized
                    guidance to enhance understanding and academic success,
                    supporting learners with tests, assignments and schedules.
                  </p>
                </div>
                <div
                  className="how it-work-section abt wow fadeInUp"
                  data-wow-duration="2s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                   
                    
                    background: "rgb(241,241,239)",
                  }}
                >
                  <figure style={{ background: "rgb(14,132,219)" }}>
                    <img
                      src="images/OnlineLanguageClasses.png"
                      alt="coach"
                      style={{ width: "210px ", height: " 40px" }}
                    />
                  </figure>
                  <h3
                    style={{
                      fontWeight: "bold",
                      
                      color: "rgb(14,132,219)",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Online Language Classes:(For Begginners & Language fanatics)
                  </h3>
                  <p>
                    Learn Sepedi, French, IsiZulu and Afrikaans from basic to
                    advanced level. Whether you're delving into Sepedi's
                    cultural diction, mastering the elegance of French,
                    exploring the melodic tones of IsiZulu or unraveling the
                    expressive beauty of Afrikaans, our online classes cater to
                    learners with academic goals in mind.
                  </p>
                </div>
                <div
                  className="how it-work-section abt wow fadeInUp"
                  data-wow-duration="1.5s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                   
                    
                    background: "rgb(241,241,239)",
                  }}
                >
                  <figure style={{  background: "rgb(126,217,87)" }}>
                    <img
                      src="images/WeeklyMathAndEnglishSaturdayClasses.png"
                      alt="coach"
                      style={{ width: "210px ", height: " 40px" }}
                    />
                  </figure>
                  <h3
                    style={{
                      fontWeight: "bold",
                     
                      color: "rgb(14,132,219)",
                      textAlign: "center",
                    }}
                  >
                    Math and English Classes (Grade 1-10):
                  </h3>
                  <p>
                    Our expert tutors guide learners through engaging and
                    comprehensive classes, fostering a deep understanding of
                    core concepts. Whether tackling complex equations or
                    refining language skills, our Math and English classes cater
                    to diverse learning needs online or face-to-face at our
                    Seshego Center.
                  </p>
                </div>
                <div
                  className="how it-work-section abt wow fadeInUp"
                  data-wow-duration="1.5s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                    
                   
                    background: "rgb(241,241,239)",
                  }}
                >
                   <figure style={{ background: "rgb(14,132,219)" }}>
                    <img
                      src="images/OneOnOneTutorialGrade1to12.png"
                      alt="coach"
                      style={{ width: "210px ", height: " 40px" }}
                    />
                  </figure>
                  <h3
                    style={{
                      fontWeight: "bold",
                      
                      color: "rgb(14,132,219)",
                      textAlign: "center",
                    }}
                  >
                    Sepedi Classes(Grade 1-9);
                  </h3>
                  <p>
                    Our Sepedi classes offer a comprehensive language-learning experience tailored to individuals
                    needs. Whether you're a beginner eager to grasp the basics or aiming to refine your fluency, our classes equip you with
                    essential language skills, and confidence to communicate effectively.
                  </p>
                </div>
               
              </div>
            </div>

            <div className="howitwork-grid" style={{ paddingTop: "70px" }}>
              <div
                className="ourservices-container2"
                // style={{
                //   paddingBottom: "70px",
                //   paddingTop: "30px",
                //   gap: "20px",
                //   paddingTop: "50px",
                //   width: "1300px",
                //   overflowX: "scroll",
                //   scrollBehavior: "smooth",
                // }}
              >
                <div
                  className="how it-work-section abt wow fadeInUp"
                  data-wow-duration=".5s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                   
                    background: "rgb(241,241,239)",
                  }}
                >
                  <figure style={{ background: "rgb(14,132,219)" }}>
                    <img
                      src="images/Grade11OnlineClasses.png"
                      alt="coach"
                      style={{ width: "210px ", height: " 40px" }}
                    />
                  </figure>
                  <h3
                    style={{
                      fontWeight: "bold",
                      
                      color: "rgb(14,132,219)",
                      textAlign: "center",
                    }}
                  >
                    Grade 11 Online classes:
                  </h3>
                  <p>
                    Our comprehensive online classes are tailored to empower
                    learners with a profound understanding of core subjects.
                    Dive into interactive sessions led by experienced tutors,
                    focusing on essential topics in Mathematics, Accounting,
                    Chemistry, and Physics.
                  </p>
                </div>
                <div
                  className="how it-work-section abt wow fadeInUp"
                  data-wow-duration="1s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                    
                    background: "rgb(241,241,239)",
                  }}
                >
                  <figure style={{ background: "rgb(126, 217, 87)" }}>
                    <img
                      src="images/ExamPreparationIconG.png"
                      alt="coach"
                      style={{ width: "210px ", height: " 50px",paddingRight:"6px" }}
                     

                    />
                  </figure>
                  <h3
                    style={{
                      fontWeight: "bold",
                      
                      color: "rgb(14,132,219)",
                      textAlign: "center",
                    
                    }}
                  >
                    Exam Preparation:Grade 4-12
                  </h3>
                  <p>
                    Our Exam Preparation program is designed to boost Confidence
                    and performance. Whether gearing up for standardized tests,
                    or school exams, our comprehensive approach ensures that
                    learners are well-prepared to face any academic challenge.
                  </p>
                </div>
                <div
                  className="how it-work-section abt wow fadeInUp"
                  data-wow-duration="2s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                    
                    background: "rgb(241,241,239)",
                  }}
                >
                  <figure style={{ background: "rgb(14,132,219)" }}>
                    <img
                      src="images/MathServiceGrade1to7.png"
                      alt="coach"
                      style={{ width: "210px ", height: " 40px" }}
                    />
                  </figure>
                  <h3
                    style={{
                      fontWeight: "bold",
                     
                      color: "rgb(14,132,219)",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Math Service (Grade 1-7)
                  </h3>
                  <p>
                    Our Math service is tailored to meet the unique needs of
                    young minds, covering the foundational concepts, builds
                    problem-solving skills, and instills a confident approach to
                    Mathematics.
                  </p>
                </div>
                <div
                  className="how it-work-section abt wow fadeInUp"
                  data-wow-duration="1.5s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                    
                    background: "rgb(241,241,239)",
                  }}
                >
                  <figure style={{ background: "rgb(126, 217, 87)" }}>
                    <img
                      src="images/Grade11RevisionClasses.png"
                      alt="coach"
                      style={{ width: "50px ", height: " 50px",paddingLeft:"4px"}}
                    />
                  </figure>
                  <h3
                    style={{
                      fontWeight: "bold",
                      
                      color: "rgb(14,132,219)",
                      textAlign: "center",
                    }}
                  >
                    Grade 11 Revision Classes:
                  </h3>
                  <p>
                    Our expert tutors are here to guide learners through a
                    seamless transition, providing targeted support in key
                    subjects to ensure a strong foundation for the challenges of
                    the new grade.
                  </p>
              </div>
                <div
                  className="how it-work-section abt wow fadeInUp"
                  data-wow-duration="1.5s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                    
                    background: "rgb(241,241,239)",
                  }}
                >
                  <figure style={{  background: "rgb(14,132,219)" }}>
                    <img
                      src="images/icons8-phone-100.png"
                      alt="coach"
                      style={{ width: "35px ", height: " 30px" }}
                    />
                  </figure>
                  <h3
                    style={{
                      fontWeight: "bold",
                      
                      color: "rgb(14,132,219)",
                      textAlign: "center",
                    }}
                  >
                    WhatsApp Service(Grade 8-12):
                  </h3>
                  <p>
                    Our WhatsApp service revolutionizes your  tutoring experience, making it convenient and accessible.
                    Experience the convenience of real-time support and tailored guidance, all at your fingertips, with our innovative WhatsApp service.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div style={{ clear: "both" }}></div> */}
          <div className="section section-padding bk">
            {/* <!--   Wrapper Start --> */}
            <div className="call-to-action-wrapper abt ab" >
              <div className="container abt d-flex quic">
                {/* <!--   Content Start --> */}
               
                {/* <!--   Content End --> */}
                {/* <!--   Image Start --> */}
                <div
                  className="call-to-action-image d-none d-lg-block wow fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                  }}
                >
                  <div className="image">
                    <img src="images/BookYourCoachImg.jpg" alt=" " />
                    <div className="dots-bg dots-bg-event  startdot "></div>
                  </div>
                </div>
                <div
                  className="call-to-action-content abt wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                   
                  }}
                >
                  <div
                    className="section-heading wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay=".2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "1s",
                      animationDelay: "0.2s",
                    }}
                  >
                    <h2 className="bookcoach abt">
                      {" "}
                      <span>Book Your </span>Academic coach
                    </h2>
                    <p>
                    {" "}
                    You can book a tutor or academic coach for your one-on-one
                    session online. And request to be matched with a tutor or
                    academic coach.{" "}
                  </p>
                  </div>
                
                  <div className="combinebutton">
                    <Link to="/multicoach" className="global-button startbtn" style={{borderRadius:"15px"}}>
                      Select a Tutor{" "}
                    </Link>
                    <Link
                      to="/selectcoachforme"
                      className="global-button startbtn" style={{borderRadius:"15px"}}
                    >
                      Match me with a Tutor{" "}
                    </Link>
                  </div>
                </div>

                {/* <!--   Image End --> */}
              </div>
              {/* <!--   Wrapper End --> */}
            </div>
          </div>
          {/* <div className='cl-logo'>
            <div className='container'>
              <div className='cl-logo-grid'>
                <figure
                  className='wow fadeInUp'
                  data-wow-duration='1s'
                  dataWowDelay='.2s'
                  style={{
                    visibility: 'visible',
                    animationDuration: '1s',
                    animationDelay: '0.4s',
                  }}
                >
                  <img src='images/cl-logo1.png' />
                </figure>
                <figure
                  className='wow fadeInUp'
                  data-wow-duration='1s'
                  dataWowDelay='.3s'
                  style={{
                    visibility: 'visible',
                    animationDuration: '1s',
                    animationDelay: '0.4s',
                  }}
                >
                  <img src='images/cl-logo3.png' />
                </figure>
                <figure
                  className='wow fadeInUp'
                  data-wow-duration='1s'
                  dataWowDelay='.4s'
                  style={{
                    visibility: 'visible',
                    animationDuration: '1s',
                    animationDelay: '0.4s',
                  }}
                >
                  <img src='images/cl-logo1.png' />
                </figure>
                <figure
                  className='wow fadeInUp'
                  data-wow-duration='1s'
                  dataWowDelay='.5s'
                  style={{
                    visibility: 'visible',
                    animationDuration: '1s',
                    animationDelay: '0.4s',
                  }}
                >
                  <img src='images/cl-logo2.png' />
                </figure>
                <figure
                  className='wow fadeInUp'
                  data-wow-duration='1s'
                  dataWowDelay='.6s'
                  style={{
                    visibility: 'visible',
                    animationDuration: '1s',
                    animationDelay: '0.4s',
                  }}
                >
                  <img src='images/cl-logo3.png' />
                </figure>
                <figure
                  className='wow fadeInUp'
                  data-wow-duration='1s'
                  dataWowDelay='.7s'
                  style={{
                    visibility: 'visible',
                    animationDuration: '1s',
                    animationDelay: '0.4s',
                  }}
                >
                  <img src='images/cl-logo1.png' />
                </figure>
              </div>
            </div>
          </div> */}
          {/* <div style={{ clear: "both" }}></div> */}

          <div className="howitwork container ht">
            <h2 style={{ paddingBottom: "50px" }}>
              How it <span>Works</span>
            </h2>

            <div className="howitwork-grid">
              <div className="howitwork-container">
                <div
                  className="how it-work-section wow fadeInUp"
                  data-wow-duration=".5s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                    background: "#7ED957",
                  }}
                >
                  <figure style={{ background: "#ffffff" }}>
                    <img src="images/consult.png" />
                  </figure>
                  <h3 style={{ fontWeight: "bold", fontSize: "30px", marginLeft:"" }}>
                    Book Consultation
                  </h3>

                  <p style={{ color: "#00006C", fontSize: "17px" }}>
                    Speak to our enrollment team to get guidance on the best
                    suitable services. And give insights on your child's needs
                    and requirements. Click here:{" "}
                    <span
                      style={{
                        fontStyle: "bold",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        window.location.href =
                          "https://forms.office.com/r/Y4QrWGTrt5​?subject=Book Consultation";
                      }}
                    >
                      https://forms.office
                      .com/r/Y4QrWGTrt5​
                    </span>
                  </p>
                </div>
                <div
                  className="how it-work-section wow fadeInUp"
                  data-wow-duration="1s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                    background: "#7ED957",
                  }}
                >
                  <figure style={{ background: "#ffffff" }}>
                    <img src="images/feedback.png" alt="coach" />
                  </figure>
                  <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                    Get Feedback
                  </h3>
                  <p style={{ color: "#00006C", fontSize: "17px" }}>
                    Request feedback from the tutor on your Child's progress in
                    the sessions. click here:{" "}
                    <span
                      style={{
                        fontStyle: "bold",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        window.location.href =
                          "https://forms.office.com/r/V13JJnZXjJ?subject=Feedback";
                      }}
                    >
                      https://forms.office
                      .com/r/V13JJnZXjJ
                    </span>
                  </p>
                </div>
                <div
                  className="how it-work-section wow fadeInUp"
                  data-wow-duration="1.5s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                    background: "#7ED957",
                  }}
                >
                  <figure style={{ background: "#ffffff" }}>
                    <img src="images/mentoring.png" alt="coach" />
                  </figure>
                  <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                    Trained Tutors
                  </h3>
                  <p style={{ color: "#00006C", fontSize: "17px" }}>
                    Tutors are provided ongoing training on tutoring tools and
                    approaches. The tutor gets coached on learner insights based
                    on feedback from parents.
                  </p>
                </div>
                <div
                  className="how it-work-section wow fadeInUp"
                  data-wow-duration="2s"
                  dataWowDelay=".2s"
                  style={{
                    visibility: "visible",
                    animationDuration: "1s",
                    animationDelay: "0.4s",
                    background: "#7ED957",
                  }}
                >
                  <figure style={{ background: "#ffffff" }}>
                    <img src="images/exam.png" />
                  </figure>
                  <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                    {" "}
                    Exam Coaching
                  </h3>
                  <p style={{ color: "#00006C", fontSize: "17px" }}>
                    Book an exam coaching session to support you in creating an
                    exam strategy and dealing with exam anxiety. Click here:{" "}
                    <span
                      style={{
                        fontStyle: "bold",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        window.location.href =
                          "https://forms.office.com/r/2v0VBKhQWU?subject=Exam Coaching";
                      }}
                    >
                      https://forms.office
                      .com/r/2v0VBKhQWU
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='abt-become'>
            <div className='container'>
              <div className='abt-become-grid'>
                <div
                  className='joinbecome wow fadeInLeft'
                  data-wow-duration='1s'
                  dataWowDelay='.5s'
                  style={{
                    visibility: 'visible',
                    animationDuration: '1s',
                    animationDelay: '0.4s',
                  }}
                >
                  <h2>
                    Join Ethuta to <span>Become a Coach</span>
                  </h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries.
                  </p>
                  <a
                    href=''
                    className='global-button wow fadeInUp'
                    data-wow-duration='1s'
                    dataWowDelay='.3s'
                    style={{
                      visibility: 'visible',
                      animationDuration: '1s',
                      animationDelay: '0.3s',
                    }}
                  >
                    Become a Coach
                  </a>
                </div>
                <div
                  className='video-image  wow fadeInRight'
                  data-wow-duration='1s'
                  dataWowDelay='.2s'
                  style={{
                    visibility: 'visible',
                    animationDuration: '1s',
                    animationDelay: '0.4s',
                  }}
                  datawowdelay='1s'
                >
                  <figure>
                    <img src='images/ab.jpg' />
                    <div className='video-gallery-wrapper edu-section-gapTop video-section-overlayto-another '>
                      <div className='video-gallery-1'>
                        <div className='thumbnail video-popup-wrapper'>
                          <button className='video-play-btn with-animation position-to-top video-popup-activation btn-secondary-color size-80'>
                            <span>
                              <svg
                                stroke='currentColor'
                                fill='currentColor'
                                strokeWidth='0'
                                viewBox='0 0 448 512'
                                className='play-icon'
                                height='1em'
                                width='1em'
                              >
                                <path d='M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z'></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div> */}

         
          <Footer />
        </>
      )}
    </>
  );
}
