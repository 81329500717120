import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import {
  getInviteLink,
  getAcceptedRequest,
  seaarch_detail,
  profile,
  get_course_comment_reply,
  get_all_push_notification,
} from "../services/web/webServices";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Modal } from "react-bootstrap";
import base_uri from "../api/base_url";
const css = `

.modal-content {
    border: none;
    
    
}

.modal-body.grade-pop.coach-profile-need {
    padding: 0;
}
.login-container-new-grid.login3svg {grid-template-columns: repeat(3, 1fr);gap: 10px;}

.login-container-new-grid.login3svg img {
    height: 100px;
}
`;
export default function Header() {
  const state = localStorage.getItem("state");
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [getData, setData] = useState([]);
  const [getSearch, setSearch] = useState(null);
  const [getLoader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [getDetail, setDetail] = useState(null);
  const [getValue, setValue] = useState(null);
  const [navbarOpen, setNavbarOpen] = useState(null);
  // const [getCourseComment,setCourseComment]=useState(null);
  const handleNavbarClose = () => {
    console.log("Closing Navbar");
    setNavbarOpen(false);
  };
  const Logout1 = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("state");
    handleNavbarClose(); // Close the navbar
    navigate("/");
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleShow1 = () => {
    setShow1(true);
  };

  const handleClose1 = () => {
    setShow1(false);
  };

  const getApi = () => {
    if (localStorage.getItem("state")) {
      setData([]);
      getInviteLink()
        .then((res) => {
          let data = res?.data?.response;
          for (let i of data) {
            if (i.saw == false) {
              setData((getData) => [...getData, i]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      getAcceptedRequest()
        .then((res) => {
          let data = res?.data?.response;
          for (let i of data) {
            if (i.accepted == false) {
              setData((getData) => [...getData, i]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

      get_course_comment_reply()
        .then((res) => {
          // console.log("res comment===>",res.data.response);
          let data = res?.data?.response;
          for (let i of data) {
            if (i?.student_saw == false) {
              setData((getData) => [...getData, i]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      get_all_push_notification()
        .then((res) => {
          let data = res?.data?.response;
          for (let i of data) {
            // if (i?.student_saw == false) {
            setData((getData) => [...getData, i]);
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });

      profile()
        .then((res) => {
          // console.log("profile==>", res.data.response);
          setDetail(res.data.response);
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
        });
    }
  };
  useEffect(() => {
    localStorage.setItem("Click", 0);
    getApi();
  }, []);
  if (getData?.length > 0) {
    localStorage.setItem("message", getData?.length);
  }
  return (
    <>
      <style>{css}</style>
      <div className="main-wrapper">
        {/* <!-- Preloader Start  --> */}
        {/* <!-- Preloader End  --> */}
        {/* <!-- Header Start  --> */}
        <div id="header" className="header section">
          <div className="container">
            {/* <!-- Header Wrapper Start  --> */}
            <div className="header-wrapper">
              {/* <!-- Header Logo Start --> */}
              <div className="header-logo">
                <NavLink to="/">
                  <img src="/images/logo.webp" alt="Logo" />
                </NavLink>
              </div>
              {/* <!-- Header Logo End -->
                            <!-- Header Menu Start --> */}
              <div className="header-menu d-none d-lg-block">
                <ul className="main-menu">
                  <li>
                    {/* <Link to="/">Home</Link> */}
                    <NavLink activeClassName="active" to="/">
                      Home
                    </NavLink>
                  </li>
                  {/* <li> <Link to="/about">Service</Link> </li> */}
                  {/* <li> <Link to="/contact">Track Record</Link> </li> */}
                  {/* <li> <Link to="/contact">Coach</Link> </li> */}
                  <li>
                    <NavLink activeClassName="active" to="/about">
                      Services
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/contact">
                      Contact
                    </NavLink>
                  </li>

                  {localStorage.getItem("auth_token") ? (
                    ""
                  ) : (
                    <li>
                      <NavLink activeClassName="active" to="/becomecoach">
                        Become a Coach
                      </NavLink>
                    </li>
                  )}

                  {state ? (
                    <>
                      <li>
                        <NavLink activeClassName="active" to="/mycourse">
                          My Course
                        </NavLink>
                      </li>
                      <li>
                        <NavLink activeClassName="active" to="/multicoach">
                          Book a Coach
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
              {/* <!-- Header Menu End --> */}
              {/* <!-- Header Meta Start --> */}
              <div className="header-meta">
                <div className="header-search d-none d-lg-block">
                  <form>
                    <input
                      type="text"
                      placeholder="Search Courses"
                      onChange={(e) => {
                        setLoader(true);
                        setValue(e.target.value);
                        seaarch_detail(e.target.value)
                          .then((res) => {
                            setLoader(false);
                            setSearch(res.data.response);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                    />
                    <a>
                      <i className="flaticon-loupe"></i>
                    </a>
                  </form>
                </div>

                {!state ? (
                  <div className="header-login d-none d-lg-flex">
                    <a className="link" onClick={() => setShow(true)}>
                      Login
                    </a>
                    {/* <Link className="link" to="/login"><i className="fa fa-user-o"></i> Login</Link> */}
                    <a className="link" onClick={() => setShow1(true)}>
                      Register
                    </a>
                  </div>
                ) : (
                  <>
                    <a
                      onClick={() => {
                        getApi();
                        navigate("/notification");
                      }}
                      className="bell-icon"
                    >
                      <i
                        className="fas fa-bell"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </a>
                    <NavLink className="user-icon" to="/profile">
                      <i
                        className="fa fa-user"
                        aria-hidden="true"
                        style={{ marginLeft: "40px" }}
                      ></i>
                    </NavLink>
                    <a
                      style={{ marginLeft: "5px" }}
                      onClick={() => navigate("/profile")}
                      className="user-name"
                    >
                      {getDetail?.name}
                    </a>

                    {getData?.length == 0 ? (
                      ""
                    ) : (
                      <div className="chat-noti-wrap">
                        <span>
                          {getData?.length > 100 ? "100+" : getData?.length}{" "}
                        </span>
                      </div>
                    )}
                  </>
                )}
                <div className="header-toggle d-lg-none">
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    style={{ marginLeft: "-225%" }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>

                {getValue ? (
                  <>
                    {getSearch?.length > 0 ? (
                      <ul className="search-Dropdown">
                        {getLoader == true ? (
                          <Box sx={{ width: "100%", height: "20px" }}>
                            <LinearProgress />
                          </Box>
                        ) : (
                          <>
                            {getSearch?.length > 0
                              ? getSearch.map((item, index) => {
                                  return (
                                    <>
                                      <li>
                                        <a
                                          onClick={() => {
                                            navigate("/search_filter", {
                                              state: { search_data: getSearch },
                                            });
                                            localStorage.setItem("Click", "1");
                                            setSearch(null);
                                          }}
                                        >
                                          Coach Name :{" "}
                                          {item?.coach[0]?.coach_name}
                                          <br></br>
                                          <span>
                                            Course Name : {item?.course_title}
                                          </span>
                                        </a>
                                      </li>
                                    </>
                                  );
                                })
                              : ""}
                          </>
                        )}
                      </ul>
                    ) : (
                      ""
                    )}
                    {getValue && !getSearch?.length ? (
                      <>
                        <ul className="search-Dropdown">
                          <li>
                            <a>No result found.</a>
                          </li>
                        </ul>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              {/* <!-- Header Meta End --> */}
            </div>
            {/* <!-- Header Wrapper End --> */}
          </div>
        </div>
        {/* <!-- Header End --> */}
        {/* <!-- Offcanvas Start --> */}
        <div className="offcanvas offcanvas-start" id="offcanvasMenu">
          <div className="offcanvas-header">
            <div className="offcanvas-logo">
              <a href=" ">
                <img src="images/logo.webp" alt="" />
              </a>
            </div>
            <button className="btn-close" data-bs-dismiss="offcanvas"></button>
          </div>
          <div className="offcanvas-body">
            <div className="offcanvas-menu">
              <ul className="main-menu">
                <li>
                  {" "}
                  <NavLink
                    activeClassName="active"
                    to="/"
                    onClick={handleNavbarClose}
                  >
                    Home
                  </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink activeClassName="active" to="/about">
                    Service
                  </NavLink>{" "}
                </li>

                <li>
                  {" "}
                  <NavLink activeClassName="active" to="/contact">
                    Contact
                  </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to="/becomecoach">Become a coach</NavLink>{" "}
                </li>
                {!state ? (
                  <>
                    <li>
                      <Link to="" onClick={handleShow}>
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link to="" onClick={handleShow1}>
                        Register
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <NavLink activeClassName="active" to="/mycourse">
                        My Course
                      </NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/multicoach">
                        Book a Coach
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/profile">Profile</NavLink>
                    </li>
                    <li>
                      <Link to="/" onClick={Logout1}>
                        Logout
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Login */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ alignItems: "center" }}>
          <Modal.Title style={{ alignItems: "center" }}>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ alignItems: "center" }}>
          <div class="modal-content">
            {/* <!-- Modal Header --> */}

            {/* <!-- Modal body --> */}
            <div class="modal-body grade-pop coach-profile-need">
              <div class="login-right  share-que">
                <div class="login-container-new">
                  <div class="login-container-new-grid">
                    <div class="login-new-content">
                      <a onClick={() => navigate("/login")}>
                        <figure>
                          <img src="images/learne-new.svg" />
                        </figure>
                        <h2>Login as Learner</h2>
                      </a>
                    </div>
                    <div class="login-new-content">
                      <a
                        onClick={() =>
                          (window.location.href = `${base_uri.coach_base_url}login`)
                        }
                      >
                        <figure>
                          <img src="images/coach-new.svg" />
                        </figure>
                        <h2>Login as Coach</h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Ends */}

      {/* Signup */}
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header>
          <Modal.Title>Sigup</Modal.Title>
          <i
            className="fas fa-cut"
            style={{ cursor: "pointer" }}
            onClick={handleClose1}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <>
            <div class="modal-content">
              {/* <!-- Modal Header --> */}

              {/* <!-- Modal body --> */}
              <div class="modal-body grade-pop coach-profile-need">
                <div class="login-right  share-que">
                  <div class="login-container-new">
                    <div class="login-container-new-grid">
                      <a onClick={() => navigate("/signup")}>
                        <div class="login-new-content">
                          <figure>
                            <img src="images/learne-new.svg" />
                          </figure>
                          <h2>Sign Up as Learner</h2>
                        </div>
                      </a>
                      <div class="login-new-content">
                        <a
                          onClick={() =>
                            (window.location.href = `${base_uri.coach_base_url}signup`)
                          }
                        >
                          <figure>
                            <img src="images/coach-new.svg" />
                          </figure>
                          <h2>Sign Up as Coach</h2>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
      {/* Ends */}
    </>
  );
}
